import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import stores from "../stores/index";

Vue.use(VueRouter);

const routes = [
    {
        path: "/login",
        name: "login",
        component: Home,
        beforeEnter(to, from, next) {
            if (stores.getters["user/status"] === "loggedIn") {
                next(to.path);
                return;
            }
            next();
        },
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        beforeEnter(to, from, next) {
            if (stores.getters["user/status"] !== "loggedIn") {
                next(`/login?to=${ to.path }`);
                return;
            }
            next();
        },
        children: [
            {
                path: "",
                //redirect: "/coming-soon",
                redirect: "/watch",
            },
            {
                path: "coming-soon",
                component: () => import("../views/ComingSoon.vue"),
            },
            {
                path: "watch",
                component: () => import("../views/Watch.vue"),
            },
        ],
    },
    {
        path: "*",
        redirect: "/login"
    }
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})

export default router
