import { apiUrl, apiVersion, } from "../config";

export async function login (idToken) {
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/login`,
        {
            "method": "POST",
            "mode": "cors",
            "credentials": "include",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            "body": JSON.stringify(
                {
                    idToken,
                }
            ),
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            return incomingToken;
        }
        throw await response.json();
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "login failed";
}

export default login;
