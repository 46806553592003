<template>
    <div class="login-card card">
        <div class="card-body font-trend-hm-slab-one p-3 p-md-4">
            <h1 class="d-none d-md-block">
                <span class="mr-2">ANI SINGERS</span>
                <br class="d-xl-none">
                <span class="font-noto-serif-tc font-weight-bold">新年會</span>
            </h1>
            <h2 class="d-md-none">
                <span class="mr-2">ANI SINGERS</span>
                <br>
                <span class="font-noto-serif-tc font-weight-bold">新年會</span>
            </h2>
            <h4 class="d-none d-md-block">
                14 FEB - 31 MAR 2021
            </h4>
            <h5 class="d-md-none">
                14 FEB - 31 MAR 2021
            </h5>
            <hr>
            <div class="position-relative">
                <form class="mb-3"
                      v-on:submit.prevent.stop="presentRedemption">
                    <b-form-group label-for="access-code">
                        <b-input-group>
                            <b-form-input id="access-code"
                                          placeholder="🎫 門票兌換碼"
                                          required
                                          v-model.trim="code">
                            </b-form-input>
                            <b-input-group-append>
                                <button class="btn btn-dark font-noto-serif-tc px-2 px-md-3"
                                        type="submit">
                                    👈 兌換
                                </button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </form>
                <button class="btn btn-link btn-block text-center font-noto-serif-tc"
                        v-on:click="login">
                    已兌換？立即登入
                </button>
                <!-- <div class="position-absolute font-noto-serif-tc notice shadow-sm py-1 px-3">
                    <h2 class="text-nowrap mb-0">
                        兌換即將開始
                    </h2>
                </div> -->
            </div>
        </div>

        <modal-redemption v-bind:code.sync="code"
                          v-model="isModalRedemptionOpen">
        </modal-redemption>
    </div>
</template>

<script>
import { BFormGroup, BFormInput, BInputGroup, BInputGroupAppend } from "bootstrap-vue";

import GAuthFactory from "../../plugins/GAuth";
import ModalRedemption from "./ModalRedemption";

export default {
    name: "LoginCard",
    components: {
        ModalRedemption,
        BInputGroup,
        BInputGroupAppend,
        BFormGroup,
        BFormInput,
    },
    data () {
        return {
            isLoading: false,
            isModalRedemptionOpen: false,

            GAuthInstance: null,
            code: "",
        };
    },
    computed: {
        queryCode () {
            return this.$route.query?.code ?? "";
        },
    },
    methods: {
        async triggerGAuth () {
            const GAuthInstance = await GAuthFactory()();
            let response;
            try {
                response = await GAuthInstance
                    .signIn(
                        {

                        }
                    );
            } catch (e) {
                console.error(e);
                // TODO: handle error
                return;
            }
            return response.getAuthResponse();
        },

        presentRedemption () {
            this.isModalRedemptionOpen = true;
        },

        async login () {
            try {
                const auth = await this.triggerGAuth();
                await this.$store.dispatch(
                    "user/login",
                    auth.id_token
                );
            } catch (e) {
                console.error(e);
                // TODO: handle error
                return;
            }
            this.code = "";
            this.$router.push("/coming-soon");
        },
    },
    mounted () {
        this.code = this.queryCode;
    },
    watch: {
        queryCode (newVal) {
            this.code = newVal;
        },
    },
}
</script>

<style lang="scss" scoped>
.login-card {
    min-width: 320px;
    box-sizing: border-box;

    background-color: #f9f3ec;
    box-shadow:
        0 0 0 2.5px #fef5a0,
        0 0 0 2.5px #d65f7a,
        0 0 0 12.5px #d65f7a,
        0 0 0 12.5px #fef5a0,
        0 0 0 15px #fef5a0;
    border-radius: 1rem;

    filter: drop-shadow(0 7.5px 15px rgba(0, 0, 0, 0.5));
}

.notice {
    top: 40%;
    left: 50%;

    background-color: #ffffff;
    transform: translate(-50%, -50%) rotate(-15deg);
    z-index: 5;
}
</style>
