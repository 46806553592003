import { apiUrl, apiVersion, } from "../config";

import localForage from "localforage";

export async function logout (tokenToBeWaived) {
    const token = tokenToBeWaived || await localForage.getItem("ani-singers-live.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/logout`,
        {
            "method": "DELETE",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${ token }`
            },
            "credentials": "include",
        }
    );

    if (response.ok) {
        return;
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "logout failed";
}

export default logout;
