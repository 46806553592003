let GoogleAuthInitPromise;

export function GAuthFactory (clientId) {
    if (GoogleAuthInitPromise) {
        return GoogleAuthInitPromise;
    }

    if (
        !clientId ||
        typeof clientId !== "string"
    ) {
        throw "Client Id has to be a string"
    }

    GoogleAuthInitPromise = async () => {
        await new Promise(
            resolve => {
                window
                    .gapi
                    .load(
                        "auth2",
                        resolve
                    );
            }
        );

        try {
            await window.gapi.auth2.init(
                {
                    "client_id": clientId
                }
            );
        } catch (e) {
            console.error(e);
            throw e;
        }

        return window.gapi.auth2.getAuthInstance();
    };

    window.init = GoogleAuthInitPromise;

    return GoogleAuthInitPromise;
}

export default GAuthFactory;
