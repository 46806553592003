import { apiUrl, apiVersion, } from "../config";

import localForage from "localforage";

export async function authenticate (tokenToBeTested) {
    const token = tokenToBeTested || await localForage.getItem("ani-singers-live.user-token");
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/authenticate`,
        {
            "method": "GET",
            "mode": "cors",
            "credentials": "include",
            "headers": {
                "Authorization": `Bearer ${ token }`
            },
        }
    );

    if (response.ok) {
        return await response.json();
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "authenticate failed";
}

export default authenticate;
