<template>
    <div class="curtain-outer"
         v-bind:class="{ 'is-reversed': isReversed, 'is-open': isOpen }">
        <div class="curtain-filler"></div>
        <div class="curtain-inner">
            <div class="curtain-unit"></div>
            <div class="curtain-unit"></div>
            <div class="curtain-unit"></div>
            <div class="curtain-unit"></div>
            <div class="curtain-unit"></div>
            <div class="curtain-unit"></div>
            <div class="curtain-unit d-none d-md-inline-block"></div>
            <div class="curtain-unit d-none d-md-inline-block"></div>
            <div class="curtain-unit d-none d-md-inline-block"></div>
            <div class="curtain-unit d-none d-md-inline-block"></div>
            <div class="curtain-unit d-none d-lg-inline-block"></div>
            <div class="curtain-unit d-none d-lg-inline-block"></div>
            <div class="curtain-unit d-none d-lg-inline-block"></div>
            <div class="curtain-unit d-none d-lg-inline-block"></div>
            <div class="curtain-unit d-none d-xl-inline-block"></div>
            <div class="curtain-unit d-none d-xl-inline-block"></div>
            <div class="curtain-unit d-none d-xl-inline-block"></div>
            <div class="curtain-unit d-none d-xl-inline-block"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Curtain",
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        isReversed: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

$accent: rgba(208, 23, 23, 1);

.curtain-outer {
    position: relative;
    height: calc(100vh - 56px - 6px);
    overflow: hidden;

    & > .curtain-filler,
    & > .curtain-inner {
        position: absolute;
        top: -10%;
        right: 0;
        bottom: 0;
        left: 0;

        transform-style: preserve-3d;
        transition: transform 3000ms ease-in-out;
        transform-origin: -120% top;

        @include media-breakpoint-up(md) {
            transition: transform 4000ms ease-in-out;
        }
    }

    & > .curtain-filler {
        width: 100%;
        background: darken($accent, 12%);
    }

    & > .curtain-inner {
        width: 100%;
        margin: auto;
        //white-space: nowrap;

        & > .curtain-unit {
            display: inline-block;

            height: 100%;
            width: 20%;
            @include media-breakpoint-up(md) {
                width: 10%;
            }

            background: repeating-linear-gradient(
                    to left,
                    $accent 8vw,
                    darken($accent, 20%) 16vw,
                    lighten($accent, 20%) 20vw
            );
            @include media-breakpoint-up(md) {
                background: repeating-linear-gradient(
                        to left,
                        $accent 4vw,
                        darken($accent, 20%) 8vw,
                        lighten($accent, 20%) 10vw
                );
            }

            background-size: 100% 100%;

            transform-origin: top;
            transform: rotate(1deg);

            animation: curtain-unit 3250ms ease-in-out infinite;

            @for $i from 1 through 14 {
                &:nth-child(#{$i}) {
                    animation-delay: $i * -100ms;
                }
            }
        }
    }

    &.is-open {
        pointer-events: none;

        & > .curtain-inner {
            transform-origin: -110% top;
            transform: scaleX(0);
        }

        & > .curtain-filler {
            transform: scaleX(0);
        }
    }

    &.is-reversed {
        transform: rotate(180deg);

        & > .curtain-inner {
            & > .curtain-unit {
                background: repeating-linear-gradient(
                        to left,
                        $accent 8vw,
                        darken($accent, 20%) 16vw,
                        lighten($accent, 20%) 20vw
                );
                @include media-breakpoint-up(md) {
                    background: repeating-linear-gradient(
                            to left,
                            $accent 4vw,
                            darken($accent, 20%) 8vw,
                            lighten($accent, 20%) 10vw
                    );
                }
                transform-origin: bottom;
            }
        }

        &.is-open {
            & > .curtain-inner {
                transform-origin: -120% bottom;
                transform: scaleX(0);
            }

            & > .curtain-filler {
                transform: scaleX(0);
            }
        }
    }
}

@keyframes curtain-unit {
    50% {
        transform: rotate(-1deg);
    }
}
</style>
