<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             content-class="rounded-lg border-0 shadow-lg"
             body-class="p-4"
             v-model="isOpen"
             v-on:hide="hideModal">
        <div class="position-absolute characters">
            <img class="position-relative character"
                 alt="ANI SINGERS"
                 src="../../assets/female1.png">
            <img class="position-relative character"
                 alt="ANI SINGERS"
                 src="../../assets/male1.png">
        </div>

        <h1 class="font-weight-bold h2 mb-3">
            感謝你參加<span class="font-trend-hm-slab-one">ANI SINGERS<span class="font-noto-serif-tc">新年會</span>2021</span>
        </h1>
        <p>
            是次活動使用Google登入，
            活動期間（2021年2月14日中午十二時正至2021年3月31日中午十二時正）已登記的Google帳號可以<strong>無限次</strong>進入音樂會播放頁面。<br>
        </p>
        <p>
            收集的資料只供是次活動使用，是次活動不會收集閣下任何的Google登入資訊。
            Ani-Singers HK將會於活動<strong>完結後銷毀</strong>所有參加者的登記及登入資料。
        </p>

        <div class="position-relative mb-4">
            <a rel="noreferrer nofollow noopener"
               href="https://developers.google.com/identity">
                了解Google Identity
            </a>
        </div>

        <div class="text-center pt-5">
            <div class="position-relative error-message-wrapper text-wrap mb-2">
                <transition name="fade-up">
                    <p class="text-danger error-message mb-0"
                       v-show="isError">
                        {{ errorMessage }}
                    </p>
                </transition>
            </div>
            <button class="btn btn-lg btn-light font-weight-bolder rounded-0 shadow-sm px-4"
                    v-bind:class="{ 'btn-loading': isLoading }"
                    v-on:click="redeem">
                <font-awesome-icon class="mr-3 text-danger"
                                   v-bind:icon="['fab', 'google']">
                </font-awesome-icon>
                <span class="font-noto-serif-tc">連結</span>Google<span class="font-noto-serif-tc">帳號</span>
            </button>
        </div>
    </b-modal>
</template>

<script>
import {
    BModal,
} from "bootstrap-vue";
import GAuthFactory from "../../plugins/GAuth";

export default {
    name: "ModalRedemption",
    components: {
        BModal,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        code: {
            type: String,
        },
    },
    data () {
        return {
            isLoading: false,
            isError: false,
            errorMessage: "",
        };
    },
    computed: {
        isOpen: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            },
        },
    },
    methods: {
        hideModal () {
            this.isError = false;
            this.errorMessage = "";
        },

        async triggerGAuth () {
            this.isLoading = true;
            const GAuthInstance = await GAuthFactory()();
            let response;
            try {
                response = await GAuthInstance
                    .signIn(
                        {

                        }
                    );
            } catch (e) {
                console.error(e);
                throw e;
            }
            this.isLoading = false;
            return response.getAuthResponse();
        },

        async redeem () {
            this.isError = false;
            this.isLoading = true;
            let auth;
            try {
                auth = await this.triggerGAuth();
            } catch (e) {
                this.isError = true;
                this.errorMessage = "連結Google帳號失敗";
                this.isLoading = false;
                return;
            }
            try {
                await this.$store.dispatch(
                    "user/redeem",
                    {
                        "idToken": auth.id_token,
                        "code": this.code
                    }
                );
            } catch (e) {
                this.isError = true;
                // 該門票已被兌換
                this.errorMessage = e === "ticket not found" ?
                    "門票兌換碼不正確" :
                    e === "ticket already redeemed" ?
                        "門票已被兌換" :
                        e === "ticket expired" ?
                            "門票已過期" :
                            e === "account has redeemed ticket already" ?
                                "該Google帳號已兌換門票。請嘗試登入。" :
                                "門票兌換碼無效";
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.$router.push("/coming-soon");
            this.isOpen = false;
            this.$emit("update:code", "");
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/breakpoints";

.characters {
    right: -1px;
    bottom: -1px;
    z-index: 0;

    @include media-breakpoint-up(md) {
        right: 1rem;
    }

    & > .character {
        width: 75px;

        &:first-child {
            top: -2.5px;
            right: -10px;
            transform: rotateY(-10deg);
        }

        &:last-child {
            transform: rotateY(10deg);
        }
    }
}

.error-message-wrapper {
    height: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    z-index: 1;
}

button {
    position: relative;
    z-index: 1;
}

.fade-up-enter-active {
    transition:
        opacity 500ms ease,
        transform 500ms ease-in-out;
}

.fade-up-leave-active {
    transition:
        opacity 500ms ease,
        transform 500ms ease-in-out;
}

.fade-up-enter,
.fade-up-leave-to {
    transform: translateY(10px);
    opacity: 0;
}
</style>
