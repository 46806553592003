import { apiUrl, apiVersion, } from "../config";

export async function redeem (
    idToken,
    code,
) {
    const response = await fetch(
        `${ apiUrl }/${ apiVersion }/redeem`,
        {
            "method": "POST",
            "mode": "cors",
            "headers": {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },
            "body": JSON.stringify(
                {
                    idToken,
                    code,
                }
            ),
        }
    );

    if (response.ok) {
        const incomingToken = await response.text();
        if (incomingToken) {
            return incomingToken;
        }
        throw await response.json();
    }

    if (response.status >= 400) {
        throw await response.text();
    }

    throw "redeem failed";
}

export default redeem;
