//import UserAPI from "../api/user";

import localForage from "localforage";

import authenticate from "../api/authenticate";
import redeem from "../api/redeem";
import login from "../api/login";
import logout from "../api/logout";

const UserStore = {
    namespaced: true,
    state: {
        status: "initial",

        message: "",

        loggedIn: false,
        loginFailed: false,
        registerFailed: false,

        user: {
            profile: {}
        },
    },
    getters: {
        status (state) {
            return state.status;
        },
        user (state) {
            return state.user;
        },
    },
    mutations: {
        resetStatus (state) {
            state.status = "loggedOut";
        },

        setLoggingIn (state) {
            state.status = "loggingIn";
        },

        setLoginSuccess (state) {
            state.status = "loggedIn";
        },

        setLoginFailed (state) {
            state.status = "loginFailed";
        },

        setVerifying2FA (state) {
            state.status = "verifying2FA";
        },

        setRegistering (state) {
            state.status = "registering";
        },

        setRegisterFailed (state) {
            state.status = "registerFailed";
        },

        setMessage (state, message) {
            state.message = message;
        },

        unsetMessage (state) {
            state.message = "";
        },

        setUser (state, user) {
            state.user = Object.assign({}, user);
        }
    },
    actions: {
        async loadToken (
            { commit }
        ) {
            const token = await localForage.getItem("ani-singers-live.user-token");
            commit("setLoggingIn");
            if (token){
                let user;
                try {
                    user = await authenticate(token);
                } catch (e) {
                    await localForage.setItem("ani-singers-live.user-token", "");
                    commit("resetStatus");
                    throw e;
                }

                commit("setLoginSuccess");
                commit("setMessage", "");
                commit("setUser", user);
                return token;
            }

            commit("resetStatus");
            throw false;
        },

        async login (
            { commit },
            idToken
        ) {
            commit("setLoggingIn");
            commit("setMessage", "");

            let token;
            try {
                token = await login(
                    idToken
                );
            } catch (e) {
                console.error(e);
                commit("setLoginFailed");
                commit("setMessage", e.message);
                throw e;
            }

            let user;
            try {
                user = await authenticate(token);
            } catch (e) {
                console.error(e);
                throw e;
            }

            commit("setLoginSuccess");
            commit("setUser", user);
            await localForage.setItem("ani-singers-live.user-token", token);
            return user;
        },

        async redeem (
            { commit },
            { idToken, code }
        ) {
            commit("setRegistering");
            commit("setMessage", "");

            let token;
            try {
                token = await redeem(
                    idToken,
                    code
                );
            } catch (e) {
                console.error(e);
                commit("setLoginFailed");
                commit("setMessage", e);
                throw e;
            }

            let user;
            try {
                user = await authenticate(token);
            } catch (e) {
                console.error(e);
                throw e;
            }

            commit("setLoginSuccess");
            commit("setUser", user);
            await localForage.setItem("ani-singers-live.user-token", token);
            return user;
        },

        async logout (
            { commit },
        ) {
            try {
                await logout();
            } catch (e) {
                console.error(e);
                commit("setMessage", e);
                throw e;
            }
            await localForage.setItem("ani-singers-live.user-token", "");
            commit("setMessage", "");
            commit("resetStatus");
        },

        setMessage (
            { commit },
            message
        ) {
            commit("setMessage", message);
        },

        unsetMessage (
            { commit },
        ) {
            commit("unsetMessage");
        },
    }
};

export default UserStore;
