<template>
    <div class="banner-wrapper d-flex flex-column align-items-center mx-auto">
        <logo class="logo"></logo>
        <div class="banner position-relative font-trend-hm-slab-one rounded px-4 py-2">
            <div class="banner-content">
                <h1 class="h3 font-weight-bolder mb-0">
                    ANI SINGERS
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import Logo from "./Logo";
export default {
    name: "Banner",
    components: {
        Logo,
    },
    data () {
        return {

        };
    },
    computed: {

    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.banner-wrapper {
    width: fit-content;
    user-select: none;

    & > .logo {
        height: 60px;
        z-index: 1;
        filter: drop-shadow(3px 1px 2px rgba(0, 0, 0, 0.75));
    }

    & > .banner {
        top: -8px;

        background-color: #b1d9ff;
        border: solid 5px #fdf7f3;
        box-shadow:
            2px 2px 3px rgba(0, 0, 0, 0.6),
            inset 0 0 5px #fdf7f3;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: -1em;
            border: 1.5em solid #b1d9ff;
            z-index: -1;
            filter: drop-shadow(
                    -1px -1px 0 #f6f1e2,
                    1px -1px 0 #f6f1e2,
                    -1px 1px 0 #f6f1e2,
                    1px 1px 0 #f6f1e2
            );
        }

        &:before {
            left: -2rem;
            border-right-width: 1.5rem;
            border-left-color: transparent;
        }

        &:after {
            right: -2rem;
            border-left-width: 1.5rem;
            border-right-color: transparent;
        }

        .banner-content {
            &:before,
            &:after {
                content: "";
                position: absolute;
                display: block;
                border-style: solid;
                border-color: #cd7777 transparent transparent transparent;
                bottom: -1rem;
            }

            &:before {
                left: 0;
                border-width: 0.7rem 0 0 1rem;
            }

            &:after {
                right: 0;
                border-width: 0.7rem 1rem 0 0;
            }
        }

        h1 {
            color: #2966a5;
            text-shadow:
                -1px -1px 0 #f6f1e2,
                1px -1px 0 #f6f1e2,
                -1px 1px 0 #f6f1e2,
                1px 1px 0 #f6f1e2;
            line-height: 1;
            filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
        }
    }
}
</style>
