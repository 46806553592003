const LayoutStore = {
    namespaced: true,
    state: {
        isOffline: false,
        isUpdateAvailable: false,

        update: null,
    },
    getters: {
        isOffline (state) {
            return state.isOffline;
        },
        isUpdateAvailable (state) {
            return state.isUpdateAvailable;
        },
        update (state) {
            return state.update;
        },
    },
    mutations: {
        updateAvailable (state, updatedServiceWorker) {
            state.isUpdateAvailable = true;
            state.update = updatedServiceWorker;
        },

        online (state) {
            state.isOffline = false;
        },

        offline (state) {
            state.isOffline = true;
        }
    },
};

export default LayoutStore;
