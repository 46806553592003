<template>
    <div class="home position-relative overflow-auto">
        <router-view class="main-view"></router-view>

        <curtain class="position-absolute curtain left"
                 v-bind:is-open="isLoggedIn">
        </curtain>
        <curtain class="position-absolute curtain right"
                 is-reversed
                 v-bind:is-open="isLoggedIn">
        </curtain>

        <transition name="fade">
            <login-card class="position-absolute"
                        v-show="!isLoggedIn">
            </login-card>
        </transition>
    </div>
</template>

<script>
import LoginCard from "../components/Home/LoginCard";
import Curtain from "../components/Home/Curtain";

export default {
    name: "Home",
    components: {
        LoginCard,
        Curtain,
    },
    metaInfo: {
        title: "兌換或登入",
    },
    data () {
        return {

        };
    },
    computed: {
        isLoggedIn () {
            return this.$store.getters["user/status"] === "loggedIn";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.home {
    background-color: rgba(17.5, 17.5, 17.5, 1);
    height: calc(100% - 62px);
}

.main-view {
    z-index: 0;
}

.login-card {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.curtain {
    top: 0;
    height: 100%;
    width: 50%;

    &.left {
        left: 0;
    }

    &.right {
        right: 0;
    }
}

.fade-enter-active {
    transition:
        opacity 1500ms ease;
}

.fade-leave-active {
    transition:
        opacity 1500ms ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
