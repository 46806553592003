<template>
    <div id="app"
         class="h-100">
        <nav-bar></nav-bar>
        <router-view></router-view>
        <b-toast no-auto-hide
                 solid
                 v-bind:visible="updateAvailabile">
            <template v-slot:toast-title>
                <strong>
                    有可用更新
                </strong>
            </template>
            <div class="d-flex justify-content-between">
                <div class="d-flex align-items-center">
                    點擊更新👉
                </div>
                <div class="d-flex align-items-center">
                    <button class="btn btn-sm btn-primary px-4"
                            v-on:click="updateApp">
                        重新載入
                    </button>
                </div>
            </div>
        </b-toast>
        <b-toast no-auto-hide
                 solid
                 v-bind:visible="offline">
            <template v-slot:toast-title>
                <strong>
                    網站離線
                </strong>
            </template>
            本網站目前處於離線狀態。網站上的信息可能已過期。
        </b-toast>
    </div>
</template>

<script>
import {
    BToast,
} from "bootstrap-vue";
import NavBar from "./components/layout/NavBar";
import localForage from "localforage";
export default {
    metaInfo: {
        titleTemplate: "%s | ANI SINGERS新年會",
    },
    components: {
        BToast,
        NavBar,
    },
    computed: {
        user () {
            return this.$store.getters["user/user"]
        },
        userStatus () {
            return this.$store.getters["user/status"]
        },
        offline () {
            return this.$store.getters["layout/isOffline"];
        },
        updateAvailabile () {
            return this.$store.getters["layout/isUpdateAvailable"];
        },
        updatedServiceWorker () {
            return this.$store.getters["layout/update"];
        },
    },
    methods: {
        updateApp () {
            if (navigator.serviceWorker) {
                if (this.updatedServiceWorker) {
                    let isRefreshing = false;
                    navigator.serviceWorker
                        .addEventListener(
                            "controllerchange",
                            () => {
                                if (isRefreshing) {
                                    return;
                                }
                                window.location.reload();
                                isRefreshing = true;
                            }
                        );
                    this.updatedServiceWorker
                        .postMessage(
                            {
                                "type": "SKIP_WAITING"
                            }
                        );
                }
            }
        },
    },
    async created () {
        try {
            await this.$store.dispatch("user/loadToken");
        } catch {
            return;
        }
        this.$router.push(
            {
                "path": this.$route.query.to ?? "/"
            }
        );
    },
}
</script>


<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-vue/src/index";

html,
body {
    height: 100%;

    background-color: rgba(245, 245, 245, 1);

    font-family:
        -apple-system,
        BlinkMacSystemFont,
        Segoe UI,
        Roboto,
        Helvetica Neue,
        Arial,
        "Microsoft JhengHei",
        sans-serif,
        Apple Color Emoji,
        Segoe UI Emoji,
        Segoe UI Symbol,
        Noto Color Emoji;

    overflow-x: hidden;

    @include media-breakpoint-down(md) {
        overflow-y: hidden;
    }
}

@font-face {
    font-family: "TrendHMSlab One";
    src:
        local("TrendHMSlab One"),
        local("TrendHMSlab-One"),
        url("./assets/TrendHMSlab-One.woff2") format("woff2"),
        url("./assets/TrendHMSlab-One.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.font-trend-hm-slab-one {
    font-family: "TrendHMSlab One", serif;
    font-weight: bold;
    font-style: normal;
}

.font-noto-serif-tc {
    font-family: "Noto Serif TC", serif;
}

.rounded-lg {
    border-radius: 1rem !important;
}

.btn {
    &.btn-loading {
        position: relative;
        pointer-events: none;
        color: transparent !important;

        &:after {
            position: absolute;
            left: calc(50% - (1rem / 2));
            top: calc(50% - (1rem / 2));

            display: block;
            height: 1.25rem;
            width: 1.25rem;

            border: 2px solid;
            border-color: rgba(150, 150, 150, 1);
            border-radius: 290486px;
            border-right-color: transparent;
            border-top-color: transparent;

            content: "";

            animation: spinAround .5s infinite linear;
        }
    }
}

@keyframes spinAround {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}
</style>
