import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./stores";

Vue.config.productionTip = false

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import { GAuthClientId } from "./config";
import GAuthFactory from "./plugins/GAuth";
GAuthFactory(GAuthClientId);

import "./init/font-awesome";
import {
    FontAwesomeIcon
} from "@fortawesome/vue-fontawesome";
Vue.component("font-awesome-icon", FontAwesomeIcon);

import { SentryDSN } from "./config";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
Sentry.init(
    {
        Vue,
        "dsn": SentryDSN,
        "integrations": [new Integrations.BrowserTracing()],
        "tracesSampleRate": 1.0,
    }
);

new Vue(
    {
        router,
        store,
        render: h => h(App)
    }
)
    .$mount("#app");
