import Vue from "vue";
import Vuex from "vuex";

import UserStore from "./UserStore";
import LayoutStore from "./LayoutStore";

Vue.use(Vuex);

export default new Vuex.Store(
    {
        modules: {
            "layout": LayoutStore,
            "user": UserStore,
        },
    }
);
