import {
    library
} from "@fortawesome/fontawesome-svg-core";

import {

} from "@fortawesome/pro-solid-svg-icons";

import {

} from "@fortawesome/pro-regular-svg-icons";

import {

} from "@fortawesome/pro-light-svg-icons";

import {

} from "@fortawesome/pro-duotone-svg-icons";

import {
    faGoogle,
} from "@fortawesome/free-brands-svg-icons";

library.add(
    faGoogle,
);
