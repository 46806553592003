<template>
    <nav class="nav-wrapper position-sticky shadow-lg">
        <div class="position-relative navbar navbar-light">
            <div>
                <logo class="position-absolute logo d-md-none"
                      v-bind:class="{ 'is-active': isLoggedIn }"></logo>
            </div>
            <banner class="banner position-absolute"
                    v-bind:class="{ 'is-lift-up': isLoggedIn }">
            </banner>
            <div>
                <a class="btn btn-light font-noto-serif-tc px-2 px-md-3"
                   target="_blank"
                   rel="noopener"
                   href="https://forms.gle/xjpbuv16cUvG1oiR9"
                   v-if="!isLoggedIn">
                    🎫
                    <span class="d-none d-md-inline">購買門票</span>
                    <span class="d-md-none">購票</span>
                </a>
                <button class="btn btn-light font-noto-serif-tc px-2 px-md-3"
                        v-else
                        v-on:click="logout">
                    👋 登出
                </button>
            </div>
        </div>
        <div class="decorative-line yellow"></div>
    </nav>
</template>

<script>
import Banner from "./NavBar/Banner";
import Logo from "./NavBar/Logo";
export default {
    name: "NavBar",
    components: {
        Logo,
        Banner,
    },
    data () {
        return {

        };
    },
    computed: {
        isLoggedIn () {
            return this.$store.getters["user/status"] === "loggedIn";
        },
    },
    methods: {
        async logout () {
            await this.$store.dispatch(
                "user/logout"
            );
            this.$router.push("/login");
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins/_breakpoints";

.nav-wrapper {
    top: 0;
    z-index: 9;
}

.navbar {
    height: 56px;
    background-color: #f8b0b2;
    overflow: visible;
    z-index: 1;
}

.logo {
    width: 65px;
    height: 100%;

    bottom: -3px;
    left: 10px;

    transform: rotateX(-90deg);
    transform-origin: bottom;
    transition: transform 1000ms ease-in-out;

    &.is-active {
        transform: rotateX(0);
    }
}

.banner {
    top: 2px;
    left: 0;
    right: 0;

    transition: transform 750ms ease-in-out;

    &.is-lift-up {
        transform: translateY(-110%);

        @include media-breakpoint-up(md) {
            transform: translateY(-52px);
        }
    }
}

.decorative-line {
    width: 100%;

    &.yellow {
        background-color: #fff4be;
        height: 6px;
    }
}
</style>
